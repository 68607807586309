/* import __COLOCATED_TEMPLATE__ from './uninstalls-chart.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { APP_PACKAGE_INSTALL_STATUS_CHANGED_EVENTS } from 'embercom/lib/reporting/queries';

export default class UninstallsChart extends Component {
  @service developerHub;
  query = APP_PACKAGE_INSTALL_STATUS_CHANGED_EVENTS;

  get filters() {
    return {
      'app_package.id': this.developerHub.appPackage.id,
      'state.id': 'UNINSTALLED',
    };
  }
}
