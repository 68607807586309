/* import __COLOCATED_TEMPLATE__ from './review-changes-list.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  columns: [
    {
      label: 'Change',
      valueComponent: 'developer-hub/app-package/review/review-changes/change',
    },
    {
      label: 'Setting',
      valueComponent: 'developer-hub/app-package/review/review-changes/setting',
    },
    {
      label: 'Value',
      valueComponent: 'developer-hub/app-package/review/review-changes/value',
    },
  ],
  rows: computed('appPackageCapability', function () {
    let apCapability = this.appPackageCapability;
    let apCapabilityConfig = this.appPackageCapabilityConfig;

    if (!apCapability && apCapabilityConfig) {
      apCapability = this.store.createFragment(this.appPackageCapabilityClass);
    } else if (!apCapability && !apCapabilityConfig) {
      return;
    }

    return apCapability.compareWith(apCapabilityConfig);
  }),
  hasChanges: notEmpty('rows'),
});
