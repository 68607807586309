/* import __COLOCATED_TEMPLATE__ from './table-dropdown-container.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { classify } from '@ember/string';
import { computed, action } from '@ember/object';
import { alias, gte, readOnly } from '@ember/object/computed';

export default Component.extend({
  intercomEventService: service(),
  store: service(),
  activeTopics: alias('webhookDefinition.topicObjects'),
  developerHub: service(),
  appPackage: readOnly('developerHub.appPackage'),
  selectedTopics: [],

  addAnalyticsEvent(topic) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      app_package_id: this.appPackage.id,
      action: 'selected_topic',
      topic,
    });
  },
  isPayloadPreviewActive: gte('appPackage.apiVersion.name', '2.6'),

  dropdownTopics: computed('topics.[]', 'webhookDefinition.topics.[]', function () {
    let processedTopics = this.topics.map((topic) => {
      let isTopicSelected = this.get('webhookDefinition.topics').includes(topic.get('id'));
      return {
        text: topic.get('id'),
        value: topic.get('id'),
        model: topic.get('id').split('.')[0],
        description: topic.get('name'),
        isDisabled: isTopicSelected,
        isSelected: isTopicSelected,
      };
    });

    let groupByModel = processedTopics.reduce((group, topic) => {
      let model = topic.model;
      group[model] = group[model] ?? [];
      group[model].push(topic);
      return group;
    }, {});

    return Object.keys(groupByModel).map((key) => ({
      heading: classify(key),
      items: groupByModel[key].sort((a, b) => a.value.localeCompare(b.value)),
    }));
  }),

  selectDropdownValue: action(function (selection) {
    this.selectedTopics = selection;
  }),

  selectDropdownClose: action(function () {
    let oldTopicArray = this.get('webhookDefinition.topics');
    let combinedTopics = new Set([...oldTopicArray, ...this.selectedTopics]);
    this.set(
      'webhookDefinition.topics',
      [...combinedTopics].sort((a, b) => a.localeCompare(b)),
    );
    this.selectedTopics.forEach((topic) => this.addAnalyticsEvent(topic));
    this.selectedTopics = [];
  }),

  removeTopic: action(function (tableTopic) {
    let oldTopicArray = this.get('webhookDefinition.topics').reject((topic) => {
      return topic === tableTopic.get('id');
    });
    this.set('webhookDefinition.topics', oldTopicArray);
  }),
});
