/* import __COLOCATED_TEMPLATE__ from './edit-scopes.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { containsByProperty } from '@intercom/pulse/lib/computed-properties';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly, bool } from '@ember/object/computed';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  classNames: ['developer-hub__app-package-permissions__item'],
  attributeBindings: ['test-developer-hub-app-package-scope-edit-container', 'disabled'],
  'test-developer-hub-app-package-scope-edit-container': true,
  messengerEnabled: bool('appPackage.changeRequest.appPackageConfig.messengerApp'),
  inboxEnabled: bool('appPackage.changeRequest.appPackageConfig.inboxApp'),

  disabled: computed('messengerEnabled', 'inboxEnabled', 'scope.id', function () {
    return this.isManagedScope(this.scope);
  }),

  /**
   * Return whether *scope* is managed by the backend.
   *
   * A managed scope should be one whose value is enforced by the backend and should not be presented to the user as
   * being editable. Whether a scope is managed or not can be determined by other application configuration values.
   *
   * For example, a 'read_conversations' scope might be considered managed when any messenger capability is also
   * enabled in the configuration. This helps prevent the application being configured by the user in a manner that is
   * technically valid, but not practically usable.
   */
  isManagedScope(scope) {
    // Ideally the business logic for determining a managed scope would be performed solely on the backend and
    // communicated via the data model to avoid the potential for drifting logic. For now, determine based on a
    // hardcoded set of source configuration keys and their affected scopes. Note that only `changeRequest` is checked
    // as it is guaranteed to contain all the relevant values, even for those that are unchanged.
    let managedScopes = new Set([
      'read_users_companies',
      'read_conversations',
      'read_admins',
      'gather_app_data',
    ]);
    return (this.messengerEnabled || this.inboxEnabled) && managedScopes.has(scope.id);
  },

  appPackageHasScope: containsByProperty(
    'appPackage.changeRequest.appPackageConfig.oauthClient.scopes',
    'scope.id',
  ),

  toggleScopeValue: action(function (scopeValue) {
    let appPackageScopes = this.get(
      'appPackage.changeRequest.appPackageConfig.oauthClient.scopes',
    ).slice();
    if (appPackageScopes.includes(scopeValue)) {
      appPackageScopes.removeObject(scopeValue);
    } else {
      appPackageScopes.pushObject(scopeValue);
    }
    this.set('appPackage.changeRequest.appPackageConfig.oauthClient.scopes', appPackageScopes);
  }),
});
