/* import __COLOCATED_TEMPLATE__ from './oauth-scopes.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  developerHub: service(),
  oauthScopes: alias('developerHub.oauthScopes'),
  changedOauthScopes: computed('newValue', 'oauthScopes', function () {
    return this.newValue.map((oauthScope) => {
      return this.oauthScopes.findBy('id', oauthScope);
    });
  }),
});
