/* import __COLOCATED_TEMPLATE__ from './workflow-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE } from 'embercom/lib/operator/custom-bots/constants';
import {
  OUTBOUND_EDITOR_ROUTES,
  OUTBOUND_INDEX_ROUTES,
} from 'embercom/models/outbound/content-wrapper';
import type Router from '@ember/routing/router-service';
import type Tag from 'embercom/objects/inbox/tag';
import type WorkflowSidedrawerService from 'embercom/services/workflow-sidedrawer-service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {
  config: any;
}

interface Signature {
  Args: Args;
}

export default class WorkflowHeader extends Component<Signature> {
  @service declare contentEditorService: any;
  @service declare router: Router;
  @service declare workflowSidedrawerService: WorkflowSidedrawerService;
  @service declare appService: $TSFixMe;
  @service declare finStandaloneService: FinStandaloneService;

  get isBackgroundWorkflow(): boolean {
    return this.contentEditorService.activeObject.visualBuilderObject?.isCustomerFacing === false;
  }

  get isSideDrawer(): boolean {
    return this.workflowSidedrawerService.sidedrawerOpen;
  }

  get isStandaloneMode() {
    return this.appService.app.canUseStandalone;
  }

  get allowPreview(): boolean {
    return (
      !this.contentEditorService.activeObject.visualBuilderObject?.isFinPreview &&
      !this.isStandaloneMode
    );
  }

  @action
  editWorkflow() {
    this.workflowSidedrawerService.editWorkflow();
  }

  @action
  async removeTag(tag: Tag) {
    await this.contentEditorService.ruleset.updateTaggingsTask.perform([], [tag]);
    this.contentEditorService.ruleset.reload();
  }

  @action
  async close() {
    if (this.isStandaloneMode) {
      this.router.transitionTo(this.finStandaloneService.workflowsReturnRoute);
    } else if (this.contentEditorService.returnPath) {
      this.router.transitionTo(this.contentEditorService.returnPath);
    } else if (this.isSideDrawer) {
      this.workflowSidedrawerService.teardown();
    } else {
      this.router.currentRouteName === OUTBOUND_EDITOR_ROUTES['custom_bot']
        ? this.router.transitionTo(OUTBOUND_INDEX_ROUTES['custom_bot'])
        : this.router.transitionTo(OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Headers::ViewMode::WorkflowHeader': typeof WorkflowHeader;
  }
}
