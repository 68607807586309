/* import __COLOCATED_TEMPLATE__ from './screenshot-upload-picker.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';

import { action } from '@ember/object';

export default Component.extend({
  removeImageUrl: action(function (imageUrl) {
    let newImageUrls = this.get('appStoreListing.imageUrls').slice();
    newImageUrls.removeObject(imageUrl);
    this.set('appStoreListing.imageUrls', newImageUrls);
  }),

  addImageUrl: action(function (imageUrl) {
    let newImageUrls = this.get('appStoreListing.imageUrls').slice();
    newImageUrls.pushObject(imageUrl);
    this.set('appStoreListing.imageUrls', newImageUrls);
  }),
});
