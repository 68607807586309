/* import __COLOCATED_TEMPLATE__ from './canvas-kit.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import compareVersions from 'embercom/lib/compare-versions';

const INBOX_APPS_VERSION = '1.3';

export default Component.extend({
  developerHub: service(),
  store: service(),
  notificationsService: service(),
  intercomConfirmService: service(),
  intercomEventService: service(),
  messengerApp: readOnly('appPackage.messengerApp'),
  changeRequestConfig: readOnly('appPackage.changeRequest.appPackageConfig'),
  enabledInboxApps: readOnly('appPackage.inboxApp.enabled'),
  enabledMessengerApps: readOnly('messengerApp.enabledMessengerApps'),
  appPackageVersion: readOnly('appPackage.apiVersion'),
  isPrivateApp: readOnly('appPackage.isPrivateApp'),
  // Validations need to fire on change request data
  shouldValidateInboxApp: readOnly('changeRequestConfig.inboxApp'),
  shouldValidateMessengerApps: readOnly('changeRequestConfig.messengerApp'),

  init() {
    this._super(...arguments);
    let inboxAppConfig =
      this.appPackage.changeRequest.appPackageConfig.inboxApp ||
      this.store.createFragment('developer-hub/inbox-app');
    let messengerAppConfig =
      this.appPackage.changeRequest.appPackageConfig.messengerApp ||
      this.store.createFragment('developer-hub/messenger-app');
    let invalidApiVersionForInboxApp =
      compareVersions(this.appPackageVersion.get('id'), INBOX_APPS_VERSION) < 0;
    let appPackageCannotUseInboxApps =
      invalidApiVersionForInboxApp && this.appPackage.inboxApp === null;

    this.setProperties({
      inboxAppConfig,
      messengerAppConfig,
      invalidApiVersionForInboxApp,
      appPackageCannotUseInboxApps,
    });
  },

  saveInboxAppTask: task(function* () {
    let { validations } = yield this.inboxAppConfig.validate();
    if (validations.isInvalid) {
      return;
    }
    this.set('appPackage.changeRequest.appPackageConfig.inboxApp', this.inboxAppConfig);
    yield this.saveAppPackageTask.perform();
  }),

  saveMessengerAppTask: task(function* () {
    let { validations } = yield this.messengerAppConfig.validate();
    if (validations.isInvalid) {
      return;
    }
    this.set('appPackage.changeRequest.appPackageConfig.messengerApp', this.messengerAppConfig);
    yield this.saveAppPackageTask.perform();
  }),

  deleteMessengerAppTask: task(function* (accordionAPI) {
    try {
      yield this.appPackage.deleteMessengerApp();
      // recreate messenger app
      this.messengerAppConfig = this.store.createFragment('developer-hub/messenger-app');
      accordionAPI.close();
    } catch (e) {
      this.notificationsService.notifyError(
        'An error occurred while deleting capabilites. Please try again.',
      );
    }
  }),

  deleteInboxAppTask: task(function* (accordionAPI) {
    try {
      yield this.appPackage.deleteInboxApp();
      // recreate inbox app
      this.inboxAppConfig = this.store.createFragment('developer-hub/inbox-app');
      accordionAPI.close();
    } catch (e) {
      this.notificationsService.notifyError(
        'An error occurred while deleting capabilites. Please try again.',
      );
    }
  }),

  saveAppPackageTask: task(function* () {
    try {
      yield this.appPackage.save();
      this.intercomEventService.trackAnalyticsEvent({
        object: 'app_package',
        app_package_id: this.get('appPackage.id'),
        app_metadata: this.get('appPackage.changeRequest'),
        enabled_inbox_app: this.enabledInboxApps,
        enabled_messenger_app: this.enabledMessengerApps,
        action: 'saved',
      });
      this.notificationsService.notifyConfirmation('Your app saved succesfully');
    } catch (e) {
      this.notificationsService.notifyError(
        'An error occurred while saving the app. Please try again.',
      );
    }
  }),

  onOpenSectionChange: task(function* (sectionId) {
    let canSectionClose = yield this.sectionCanClose.perform();
    if (canSectionClose) {
      this.set('openSectionId', sectionId);
    }
  }),

  hasCanvasKitChanges() {
    let inboxAppHasDiff = isPresent(Object.keys(this.inboxAppConfig.changedAttributes()));
    let messengerAppHasDiff = isPresent(Object.keys(this.messengerAppConfig.changedAttributes()));
    return inboxAppHasDiff || messengerAppHasDiff;
  },

  sectionCanClose: task(function* () {
    if (!this.hasCanvasKitChanges()) {
      return true;
    }

    let confirmed = yield this.intercomConfirmService.confirm({
      title: 'Unsaved Changes',
      body: 'You have unsaved changes. Do you want to discard them?',
      confirmButtonText: 'Discard',
    });

    if (confirmed) {
      this.rollbackChanges();
      return true;
    } else {
      return false;
    }
  }),

  rollbackChanges() {
    this.inboxAppConfig.rollbackAttributes();
    this.messengerAppConfig.rollbackAttributes();
  },

  cancelEdit: action(function (sectionAPI) {
    this.rollbackChanges();
    if (sectionAPI) {
      sectionAPI.close();
    }
  }),
});
