/* import __COLOCATED_TEMPLATE__ from './base-form.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

export default class BaseForm extends Component {
  @service store;
  @service intercomEventService;
  @service notificationsService;

  @tracked appStoreListingConfig =
    this.appPackage.changeRequest.appPackageConfig.appStoreListing ||
    this.store.createFragment('developer-hub/app-store-listing');

  get appPackage() {
    return this.args.appPackage;
  }

  get appStoreListing() {
    return this.appPackage.appStoreListing;
  }

  get appPackageConfig() {
    return this.appPackage.changeRequest.appPackageConfig;
  }

  get saveButtonEnabled() {
    return this.appPackage.hasDirtyAttributes || this.appStoreListingConfig?.hasDirtyAttributes;
  }

  @task *save() {
    this._cleanupHiddenFields();
    yield this._saveAppPackage.perform();
  }

  _cleanupHiddenFields() {
    if (this.appPackageConfig.installationMode === 'direct') {
      this.appStoreListingConfig.learnMoreUrl = null;
    }
    if (this.appPackageConfig.installationMode === 'website') {
      this.appPackageConfig.installUrl = null;
    }
  }

  _appStoreListingIsPresent() {
    return (
      this.appPackage.changeRequest.appPackageConfig.appStoreListing ||
      this.appStoreListingConfig.learnMoreUrl
    );
  }

  _isInvalid(validations) {
    let { isInvalid, errors } = validations;
    return isInvalid && errors.some((e) => this.args.trackedAttributes.includes(e.attribute));
  }

  @task *_saveAppPackage() {
    try {
      if (this._appStoreListingIsPresent()) {
        this.appPackage.changeRequest.appPackageConfig.appStoreListing = this.appStoreListingConfig;
      }

      let { validations } = yield this.appPackageConfig.validate();
      if (this._isInvalid(validations)) {
        return;
      }

      yield this.appPackage.save();
      this._trackAnalyticsEvent('saved');
      this.notificationsService.notifyConfirmation('Your app was saved succesfully');
    } catch (e) {
      this.notificationsService.notifyError(
        'An error occurred while saving your changes. Please try again.',
      );
    }
  }

  _trackAnalyticsEvent(eventAction) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'app_package',
      section: `customer_workspaces_${this.args.section}`,
      app_package_id: this.appPackage.id,
      app_metadata: this.appPackage.changeRequest,
      action: eventAction,
    });
  }

  @action cancel(accordionBody) {
    this.appPackage.rollbackAttributes();

    if (accordionBody) {
      accordionBody.accordionAPI.closeSection();
    }
  }
}
