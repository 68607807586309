/* import __COLOCATED_TEMPLATE__ from './custom-bots-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  launchModal: () => void;
  launchTriggerModal: () => void;
}

export default class CustomBotsHeader extends Component<Args> {
  @tracked video?: { id: string; titleKey: string };

  get learnList() {
    return [
      {
        items: [
          {
            component: 'custom-bots/learn-more-dropdown-item',
            componentShouldReplaceItem: true,
            componentAttrs: {
              openVideo: this.openVideoModal,
              closeVideo: this.closeVideoModal,
            },
          },
        ],
      },
    ];
  }

  @action
  openVideoModal(id: string, titleKey: string, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.video = { id, titleKey };
  }

  @action
  closeVideoModal() {
    this.video = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CustomBots::CustomBotsHeader': typeof CustomBotsHeader;
  }
}
