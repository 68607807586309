/* import __COLOCATED_TEMPLATE__ from './edit-urls.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, equal } from '@ember/object/computed';

export default Component.extend({
  intercomEventService: service(),
  redirectUrls: alias('appPackage.changeRequest.appPackageConfig.oauthClient.redirectUrls'),
  shouldHideClose: equal('redirectUrls.length', 1),

  addAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'redirect_url',
      action,
    });
  },

  removeRow: action(function (index) {
    this.addAnalyticsEvent('deleted');
    this.redirectUrls.removeAt(index);
  }),

  actions: {
    addRow() {
      this.addAnalyticsEvent('added');
      this.redirectUrls.createFragment('developer-hub/redirect-url', { url: '' });
    },
  },
});
