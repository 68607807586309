/* import __COLOCATED_TEMPLATE__ from './review-state.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { and, equal, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  developerHub: service(),
  appPackage: readOnly('developerHub.appPackage'),
  draftState: equal('appPackage.changeRequest.state', 'draft'),
  inReviewState: equal('appPackage.changeRequest.state', 'in_review'),
  actionRequiredState: equal('appPackage.changeRequest.state', 'action_required'),
  approvedState: equal('appPackage.changeRequest.state', 'approved'),
  privateListingApp: equal('appPackage.appStoreListing.publiclyAvailable', false),
  approvedPrivateListingState: and('approvedState', 'privateListingApp'),
  privateAppWithDraftPendingListing: and(
    'appPackage.isPrivateApp',
    'draftState',
    'developerHub.appStoreListingHasChanges',
  ),
  inDraftWithPendingChanges: and('draftState', 'developerHub.appPackageHasChanges'),
  privateAppWithDraftUsingOauth: and(
    'appPackage.isPrivateApp',
    'draftState',
    'appPackage.oauthClient.useOAuthFlow',
  ),

  classNames: ['u__centered-text-block', 'banner'],

  classNameBindings: ['bannerOptionClass'],

  bannerOptionClass: computed(
    'inDraftWithPendingChanges',
    'privateAppWithDraftPendingListing',
    'inReviewState',
    'actionRequiredState',
    'approvedPrivateListingState',
    'approvedState',
    function () {
      if (
        this.inDraftWithPendingChanges ||
        this.privateAppWithDraftPendingListing ||
        this.inReviewState ||
        this.privateAppWithDraftUsingOauth
      ) {
        return 'o__warning';
      } else if (this.actionRequiredState) {
        return 'o__error';
      } else if (this.approvedPrivateListingState || this.approvedState) {
        return 'o__confirmation';
      }
      return '';
    },
  ),

  icon: computed(
    'inDraftWithPendingChanges',
    'privateAppWithDraftPendingListing',
    'inReviewState',
    'actionRequiredState',
    'approvedPrivateListingState',
    'approvedState',
    function () {
      if (
        this.inDraftWithPendingChanges ||
        this.privateAppWithDraftPendingListing ||
        this.inReviewState ||
        this.privateAppWithDraftUsingOauth
      ) {
        return 'alert';
      } else if (this.actionRequiredState) {
        return 'error';
      } else if (this.approvedPrivateListingState || this.approvedState) {
        return 'check';
      }
      return '';
    },
  ),
});
