/* import __COLOCATED_TEMPLATE__ from './multi-select.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { getAppPartnerOptionsData } from 'embercom/lib/developer-hub/app-partner-options';
export default class MultiSelect extends Component {
  area = this.args.area;
  existingOptions = this.args.existingOptions;
  selectedOptions = this.setSelectedOptions(this.area, this.existingOptions);

  setSelectedOptions(area, existingOptions) {
    let selectedOptions = [];
    if (existingOptions && existingOptions.length) {
      existingOptions.forEach((option) => {
        selectedOptions.push(getAppPartnerOptionsData(option, area));
      });
    }
    return selectedOptions;
  }
}
