/* import __COLOCATED_TEMPLATE__ from './access-token-display.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AccessTokenDisplay extends Component {
  @service developerHub;

  get appPackage() {
    return this.developerHub.appPackage;
  }
}
