/* import __COLOCATED_TEMPLATE__ from './duration-form.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLFormElement;
  Args: {
    format: string;
    durationToDisplay: number;
    onFieldChange: (value: number) => void;
    isReadOnly?: boolean;
    customIsValid?: boolean;
    customErrorMessage?: string;
    maxDuration?: number;
    minDuration?: number;
  };
}

const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_AN_HOUR = 60 * SECONDS_IN_A_MINUTE;
const SECONDS_IN_AN_DAY = 24 * SECONDS_IN_AN_HOUR;

export default class DurationForm extends Component<Signature> {
  @tracked days: number | string | undefined;
  @tracked hours: number | string | undefined;
  @tracked minutes: number | string | undefined;
  @tracked seconds: number | string | undefined;

  maxHours = 23;
  maxMinutes = 59;
  maxSeconds = 59;
  minHours = 0;
  minSeconds = 0;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.formatDuration();
  }

  get duration() {
    return this.durationInSeconds();
  }

  get durationIsValid() {
    if (this.args.customIsValid !== undefined) {
      return this.args.customIsValid;
    }

    if (this.args.maxDuration !== undefined && this.duration > this.args.maxDuration) {
      return false;
    }

    if (this.args.minDuration !== undefined && this.duration < this.args.minDuration) {
      return false;
    }

    return true;
  }

  get maxDays() {
    if (this.args.maxDuration === undefined) {
      return null;
    }
    return Math.floor(this.args.maxDuration / SECONDS_IN_AN_DAY);
  }

  get minDays() {
    if (this.args.minDuration === undefined) {
      return 0;
    }
    return Math.floor(this.args.minDuration / SECONDS_IN_AN_DAY);
  }

  get minMinutes() {
    if (this.args.minDuration === undefined || this.args.minDuration < SECONDS_IN_A_MINUTE) {
      return 0;
    }
    return Math.floor(this.args.minDuration / SECONDS_IN_A_MINUTE);
  }

  get showDays() {
    return this.args.format.includes('d');
  }

  get showHours() {
    return this.args.format.includes('h');
  }

  get showMinutes() {
    return this.args.format.includes('m');
  }

  get showSeconds() {
    return this.args.format.includes('s');
  }

  formatDuration() {
    let durationToFormat = this.args.durationToDisplay || 0;

    this.days = Math.floor(durationToFormat / SECONDS_IN_AN_DAY);
    this.hours = Math.floor((durationToFormat % SECONDS_IN_AN_DAY) / SECONDS_IN_AN_HOUR);
    this.minutes = Math.floor(
      ((durationToFormat % SECONDS_IN_AN_DAY) % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE,
    );
    this.seconds = Math.floor(
      ((durationToFormat % SECONDS_IN_AN_DAY) % SECONDS_IN_AN_HOUR) % SECONDS_IN_A_MINUTE,
    );
  }

  durationInSeconds() {
    let days = this.parseInt(this.days);
    let hours = this.parseInt(this.hours);
    let minutes = this.parseInt(this.minutes);
    let seconds = this.parseInt(this.seconds);
    return (
      days * SECONDS_IN_AN_DAY +
      hours * SECONDS_IN_AN_HOUR +
      minutes * SECONDS_IN_A_MINUTE +
      seconds
    );
  }

  parseInt(value: string | number | undefined): number {
    if (value === undefined) {
      return 0;
    } else if (value === '') {
      return 0;
    } else if (typeof value === 'string') {
      return parseInt(value, 10);
    } else {
      return value;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DurationForm: typeof DurationForm;
  }
}
