/* import __COLOCATED_TEMPLATE__ from './show.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { notEmpty } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Admin from 'embercom/models/admin';

export default Component.extend({
  store: service(),
  hasAppStoreListing: notEmpty('appPackage.appStoreListing'),
  admin: computed('appPackage.adminId', function () {
    return Admin.peekAndMaybeLoad(this.store, this.get('appPackage.adminId'));
  }),
});
