/* import __COLOCATED_TEMPLATE__ from './review-warning.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { and, not, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  developerHub: service(),
  appPackage: readOnly('developerHub.appPackage'),
  noPendingChanges: not('developerHub.appPackageHasChanges'),
  publicAppWithoutPendingChanges: and('appPackage.isPublicApp', 'noPendingChanges'),
});
