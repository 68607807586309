/* import __COLOCATED_TEMPLATE__ from './teammate-accordion.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { and, or, not, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default Component.extend({
  appService: service(),
  store: service(),
  app: readOnly('appService.app'),
  hasNonEmptyValues: or('inboxApp.enabled', 'inboxApp.initializeUrl', 'inboxApp.submitUrl'),
  saveButtonEnabled: and('inboxApp.hasDirtyAttributes', 'hasNonEmptyValues'),

  showConfirmModal: false,
  notPersistedModel: not('inboxApp.isNew'),
  disabledAllCapabilites: not('inboxApp.enabled'),
  shouldShowDeleteButton: and('notPersistedModel', 'disabledAllCapabilites', 'isPrivateApp'),

  deleteInboxAppAndCloseModal: action(function () {
    this.deleteInboxAppTask();
    this.set('showConfirmModal', false);
  }),
});
