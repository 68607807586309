/* import __COLOCATED_TEMPLATE__ from './app-package-new.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),

  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  newAppPackage: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'new_app',
      action: 'clicked',
    });
    this.modalService.openModal('developer-hub/app-package-new-modal', {
      apps: this.apps,
    });
  }),
});
