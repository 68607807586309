/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import reviewStateMachine from 'embercom/lib/developer-hub/review-state-machine';
import { isBlank } from '@ember/utils';

export default class Edit extends Component {
  @service developerHub;
  @service appService;
  @tracked showApplication = true;

  get videoUrlError() {
    if (
      this.developerHub.isPublicApp &&
      !this.developerHub.oauthClientHasChanges &&
      isBlank(this.args.appPackage.changeRequest.videoUrl)
    ) {
      return '';
    } else {
      return this.args.appPackage.changeRequest.videoUrlErrors;
    }
  }

  get shouldShowTabs() {
    let { displayChangeDiff } = reviewStateMachine(this.args.appPackage);
    return displayChangeDiff;
  }

  get actionRequired() {
    return this.args.appPackage.changeRequest.state === 'action_required';
  }

  get showHealthCheckUrlBanner() {
    return isBlank(this.args.appPackage.changeRequest.appPackageConfig.healthCheckUrl);
  }
}
