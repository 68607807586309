/* import __COLOCATED_TEMPLATE__ from './edit-base.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class EditBase extends Component {
  @service intercomEventService;

  @tracked
  showPreview = false;
  showInstalledPreview = false;

  @action
  togglePreview() {
    this.showPreview = !this.showPreview;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'preview',
    });
  }
}
