/* import __COLOCATED_TEMPLATE__ from './image-upload-component.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isEmpty } from '@ember/utils';
import { task } from 'ember-concurrency';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import ImageUploadComponent from 'embercom/components/mixins/image-upload-component';

export default Component.extend(ImageUploadComponent, {
  notificationsService: service(),
  developerHub: service(),
  imageUrl: null,
  imageUrls: null,
  maxFileSizeInBytes: 10485760,

  maxFileSize: computed('maxFileSizeInBytes', function () {
    return `${Math.floor(this.maxFileSizeInBytes / 1024 / 1024)}MB`;
  }),

  fileIsTooLarge: computed('file', function () {
    let file = this.file;
    return !file || file.size > this.maxFileSizeInBytes;
  }),

  change(e) {
    this._super(...arguments);
    this._uploadImage();
  },

  setFile(file) {
    if (!this.file) {
      this.setProperties({
        isDragging: false,
        file,
        hasFile: true,
        saving: false,
      });
    }
  },

  resetFile() {
    this.set('file', null);
    $('input:file').val('');
  },

  _uploadImage() {
    if (isEmpty(this.file)) {
      return;
    }

    let error = this.message;
    if (!isEmpty(error)) {
      this.notificationsService.notifyError(error);
      return;
    }

    let file = this.file;
    let uploadType = this.uploadType;
    this.uploadScreenshotImageTask.perform(file, uploadType);
    this.resetFile();
  },

  uploadScreenshotImageTask: task(function* (image, uploadType) {
    try {
      let result = yield this.get('developerHub.appPackage').uploadImage(image, uploadType);
      this.addImageUrl(result.url);
      this.notificationsService.notifyConfirmation('Your image has been uploaded.');
    } catch (e) {
      if (!isEmpty(e.jqXHR.responseJSON) && !isEmpty(e.jqXHR.responseJSON.errors)) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError('There was a problem uploading your image.');
      }
    }
  }),

  showFilePicker: action(function () {
    $('input:file', this.element).click();
  }),
});
