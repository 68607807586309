/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';

export default class Summary extends Component {
  @service contentEditorService;
  @service appService;
  @service guideLibraryService;

  @tracked customBot = this.contentEditorService.activeObject;
  @tracked hasDismissedOptInCopy = false;

  helpCollectionLink =
    'https://www.intercom.com/help/en/collections/2094823-installing-the-intercom-messenger';

  get editorConfiguration() {
    let { editorConfig } = CUSTOM_BOT_CONFIG[this.customBot.target];
    if (!editorConfig) {
      throw new Error(
        `Cannot resolve path configuration class for custom bot type=${this.customBot.type}`,
      );
    }
    return editorConfig;
  }

  get shouldShowOptInCopy() {
    return !this.hasDismissedOptInCopy && this.customBot.shouldShowOptInCopy;
  }

  get isMessengerInstalled() {
    return this.guideLibraryService.isMessengerInstalled;
  }

  get isMessengerInstallationRequired() {
    if (!this.customBot.isTriggerable) {
      return true;
    }

    if (this.customBot.targetChannels?.some((item) => ['web', 'ios', 'android'].includes(item))) {
      return true;
    }

    return false;
  }
}
