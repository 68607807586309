/* import __COLOCATED_TEMPLATE__ from './app-package-new-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';
import { computed } from '@ember/object';
import { oneWay, readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  appService: service(),
  store: service(),
  regionService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  router: service(),
  currentAdmin: readOnly('appService.app.currentAdmin'),
  tagName: '',
  selectedWorkspaceId: oneWay('model.apps.firstObject.id'),
  selectedWorkspace: findByProperty('model.apps', 'id', 'selectedWorkspaceId'),
  isCloseActionDisabled: readOnly('createAppPackage.isRunning'),

  workspaceDropdownItems: computed('model.apps', 'selectedWorkspaceId', function () {
    return this.get('model.apps').map((workspace) => ({
      text: workspace.get('name'),
      value: workspace.get('id'),
      isSelected: workspace.get('id') === this.selectedWorkspaceId,
    }));
  }),

  hasAppPackageNameError: false,
  isCreatingNewDeveloperWorkspace: false,

  resetProperties() {
    this.setProperties({
      appPackageName: undefined,
      newDeveloperWorkspaceName: undefined,
      hasAppPackageNameError: false,
      isCreatingNewDeveloperWorkspace: false,
    });
  },

  closeModal() {
    this.modalService.closeModal();
  },

  createAppPackage: task(function* () {
    if (this.isCreatingNewDeveloperWorkspace) {
      let developerWorkspace = yield this.createNewDeveloperWorkspaceTask.perform();
      this.set('selectedWorkspaceId', developerWorkspace.get('id'));
      this.set('isCreatingNewDeveloperWorkspace', false);
    }

    let appPackage = this.store.createRecord('developer-hub/app-package', {
      ownerAppId: this.selectedWorkspaceId,
      name: this.appPackageName,
    });

    let nameErrors = appPackage.get('nameErrors');
    if (nameErrors.length) {
      this.set('appPackageNameErrors', nameErrors);
      appPackage.unloadRecord();

      return;
    }
    yield appPackage.save();
    this.intercomEventService.trackAnalyticsEvent({
      object: 'new_app_package',
      place: 'developer_hub',
      action: 'created',
      owner_workspace_id: appPackage.get('ownerAppId'),
    });

    this.resetProperties();
    this.closeModal();

    this.router.transitionTo(
      'apps.app.developer-hub.app-packages.app-package.oauth',
      appPackage.get('ownerAppId'),
      appPackage,
    );
  }),

  createNewDeveloperWorkspaceTask: task(function* () {
    let developerWorkspace;

    try {
      developerWorkspace = yield this.store
        .createRecord('app', {
          name: this.newDeveloperWorkspaceName,
          developer_workspace: true,
        })
        .save();
      let normalizedDeveloperWorkspace = this.store.normalize(
        'developer-hub/app',
        developerWorkspace,
      );
      this.store.push(normalizedDeveloperWorkspace);
    } catch (e) {
      this.notificationsService.notifyError('Could not create a new development workspace.');
      throw e;
    }
    let apps = this.currentAdmin.apps.slice(0);
    apps.push({
      id: developerWorkspace.id,
      name: developerWorkspace.name,
      is_test_app: developerWorkspace.is_test_app,
      test_app_id: developerWorkspace.test_app_id,
      active_product_ids: developerWorkspace.active_product_ids,
    });
    this.currentAdmin.set('apps', apps);
    this.intercomEventService.trackAnalyticsEvent({
      object: 'create_dev_workspace',
      place: 'developer_hub',
      action: 'clicked',
      new_workspace_id: developerWorkspace.get('id'),
    });

    return developerWorkspace;
  }),

  actions: {
    close() {
      if (this.isCloseActionDisabled) {
        return;
      }
      this.intercomEventService.trackAnalyticsEvent({
        object: 'app_creation',
        place: 'developer_hub',
        action: 'cancelled',
      });
      this.resetProperties();
      this.closeModal();
    },
    toggleCreateNewDeveloperWorkspace() {
      this.toggleProperty('isCreatingNewDeveloperWorkspace');
    },
  },
});
