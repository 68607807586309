/* import __COLOCATED_TEMPLATE__ from './app-package-state.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { and, equal, not, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  publiclyAvailableListing: equal('row.appStoreListing.publiclyAvailable', true),
  notPubliclyAvailableListing: not('publiclyAvailableListing'),

  publicState: readOnly('row.isPublicApp'),

  private: readOnly('row.isPrivateApp'),
  public: and('publicState', 'notPubliclyAvailableListing'),
  published: and('publicState', 'publiclyAvailableListing'),

  publicTooltipText:
    'This app can be installed by anyone via your OAuth flow, but it’s not listed on the App Store',
  privateTooltipText: 'This app can only be used by your team on workspaces you own',
  publishedTooltilText: 'This app is listed on the App Store and anyone can install it',
  tooltipText: computed('public', 'private', 'published', function () {
    if (this['public']) {
      return this.publicTooltipText;
    } else if (this.published) {
      return this.publishedTooltilText;
    } else if (this['private']) {
      return this.privateTooltipText;
    }
  }),
});
