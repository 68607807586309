/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  getSecurityCertificates,
  getCustomersPrimarilyServed,
  getTeamSizeOptions,
  getCustomersSizeOptions,
} from 'embercom/lib/developer-hub/app-partner-options';

export default class Show extends Component {
  @tracked appPartner = this.args.appPackage.appPartner;

  teamSizeOptions = getTeamSizeOptions();

  customersSizeOptions = getCustomersSizeOptions();

  securityCertificateOptions = getSecurityCertificates();

  customersPrimarilyServedOptions = getCustomersPrimarilyServed();
}
