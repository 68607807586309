/* import __COLOCATED_TEMPLATE__ from './pricing-model.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { getPricingModelData } from 'embercom/lib/developer-hub/pricing-models';

export default Component.extend({
  pricingModel: computed('newValue', function () {
    return getPricingModelData(this.newValue);
  }),
  pricingModelName: ternaryToProperty('pricingModel', 'pricingModel.name', 'newValue'),
});
