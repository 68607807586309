/* import __COLOCATED_TEMPLATE__ from './uninstall-feedback.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { APP_PACKAGE_UNINSTALL_REASONS } from 'embercom/models/developer-hub/app-package/reports/uninstall-feedback';

export default class UninstallFeedback extends Component {
  @service developerHub;
  @service store;
  @service intercomEventService;

  @tracked report;
  @tracked showModal = false;

  constructor() {
    super(...arguments);
    this.fetchReport.perform();
  }

  get commentCount() {
    if (!this.report) {
      return false;
    }
    return this.report.report.comments.length;
  }

  get displayFeedback() {
    if (!this.report) {
      return false;
    }

    let eventsCount = Object.values(this.report.report.distribution).reduce(
      (total, value) => total + value,
    );

    return eventsCount > 0;
  }

  get orderedKeys() {
    return Object.keys(this.report.report.distribution)
      .filter((key) => key !== 'other')
      .sort();
  }

  get distributionData() {
    if (!this.report) {
      return null;
    }
    let { distribution } = this.report.report;

    return this.orderedKeys.map(function (key) {
      return {
        name: APP_PACKAGE_UNINSTALL_REASONS[key],
        value: distribution[key],
      };
    });
  }

  @task
  *fetchReport() {
    let { id: appPackageId } = this.developerHub.appPackage;
    let { start, end } = this.args.range;
    this.report = yield this.store.queryRecord(
      'developer-hub/app-package/reports/uninstall-feedback',
      {
        appPackageId,
        start,
        end,
      },
    );
  }

  @action
  openModal() {
    this.showModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      object: 'detailed_feedback_modal',
      action: 'opened',
    });
  }

  @action
  closeModal() {
    this.showModal = false;
    this.intercomEventService.trackAnalyticsEvent({
      object: 'detailed_feedback_modal',
      action: 'closed',
    });
  }
}
